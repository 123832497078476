import DetailCard from 'components/Molecules/DetailCard'
import AuditTable from 'components/Molecules/AuditTable'
import CandidTable from 'components/Molecules/CandidTable'

import { ai, compchem, medchem } from 'data'

export default function AdditionalDetails({ molecule, refetch }) {
  return (
    <>
      {/* insight data */}
      <div className="w-full h-px bg-gray-100 my-2" />
      <DetailCard title="AI Research" molecule={molecule} fields={ai} />
      <div className="w-full h-px bg-gray-100 my-2" />

      <DetailCard
        title="Computational Chemistry"
        molecule={molecule}
        fields={compchem}
      />
      <div className="w-full h-px bg-gray-100 my-2" />

      <DetailCard
        title="Medicinal Chemistry"
        molecule={molecule}
        fields={medchem}
      />

      {/* Candid Table */}
      <div className="w-full h-px bg-gray-100 my-2" />
      <CandidTable molecule={molecule} refetch={refetch} />

      <div className="w-full h-px my-3" />
      {molecule?.audit_logs?.length > 0 && (
        <AuditTable audit_logs={molecule?.audit_logs} />
      )}
    </>
  )
}
