/* eslint-disable no-underscore-dangle */
import { useState } from 'react'

import Modal from 'react-modal'

import { api } from 'api'
import { general, ai, compchem, medchem } from 'data'
import { notify, notifyError } from 'utils'

Modal.setAppElement('#root')

export default function EditMoleculeModal({
  isOpen,
  closeModal,
  molecule,
  refetch,
}) {
  const [moleculeInfo, setMoleculeInfo] = useState({ ...molecule })

  const handleSave = async () => {
    try {
      await api.put(`/virtualDB/update/${molecule._id}`, moleculeInfo)
      notify('Molecule updated successfully', 'SUCCESS')

      refetch()
      closeModal()
    } catch (err) {
      notifyError(err)
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setMoleculeInfo({ ...moleculeInfo, [name]: value })
  }

  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal} style={customStyles}>
      <button
        className="btn-red text-white"
        type="button"
        onClick={() => closeModal()}
      >
        Close
      </button>

      <button
        className="btn-green text-white"
        type="button"
        onClick={handleSave}
      >
        Save
      </button>
      <div className="flex items-baseline justify-between">
        <h2 className="text-red-500 text-2xl font-bold">Edit Molecule</h2>
      </div>
      <div>
        <div className="w-full p-0.5 bg-gradient-to-r from-pink-300 to-red-500 w-12/12" />
        <div className="grid grid-cols-2 gap-1 my-4">
          {general.map((item) => (
            <label
              htmlFor={item.name}
              key={item.name}
              className="block text-sm text-gray-500 dark:text-gray-300"
            >
              {item.name}
              {item.type === 'textarea' ? (
                <textarea
                  name={item.value}
                  className="hgic-input"
                  cols="30"
                  onChange={handleChange}
                  value={moleculeInfo[item.value]}
                ></textarea>
              ) : (
                <input
                  onChange={handleChange}
                  className="hgic-input"
                  type={item.type}
                  name={item.value}
                  value={
                    moleculeInfo[item.value] === null
                      ? ''
                      : moleculeInfo[item.value]
                  }
                />
              )}
            </label>
          ))}
        </div>
        <div className="w-full p-0.5 bg-gradient-to-r from-pink-300 to-red-500 w-12/12" />
        <div className="grid grid-cols-2 gap-1 my-4">
          {ai.map((item) => (
            <label
              htmlFor={item.name}
              key={item.name}
              className="block text-sm text-gray-500 dark:text-gray-300"
            >
              {item.name}
              <input
                onChange={handleChange}
                className="hgic-input"
                type={item.type}
                name={item.value}
                value={
                  moleculeInfo[item.value] === null
                    ? ''
                    : moleculeInfo[item.value]
                }
              />
            </label>
          ))}
        </div>

        <div className="w-full p-0.5 bg-gradient-to-r from-pink-300 to-red-500 w-12/12" />
        <div className="grid grid-cols-2 gap-1 my-4">
          {compchem.map((item) => (
            <label
              htmlFor={item.name}
              key={item.name}
              className="block text-sm text-gray-500 dark:text-gray-300"
            >
              {item.name}
              {item.type === 'select' ? (
                <select
                  name={item.value}
                  onChange={handleChange}
                  className="hgic-input"
                  value={moleculeInfo[item.value]}
                >
                  {item.options.map((option) => (
                    <option key={`1${option}`} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              ) : (
                <input
                  onChange={handleChange}
                  className="hgic-input"
                  type={item.type}
                  name={item.value}
                  value={
                    moleculeInfo[item.value] === null
                      ? ''
                      : moleculeInfo[item.value]
                  }
                />
              )}
            </label>
          ))}
        </div>

        <div className="w-full p-0.5 bg-gradient-to-r from-pink-300 to-red-500 w-12/12" />
        <div className="my-4 grid grid-cols-2 gap-1">
          {medchem.map((item) => (
            <label
              htmlFor={item.name}
              key={item.name}
              className="block text-sm text-gray-500 dark:text-gray-300"
            >
              {item.name}
              <input
                onChange={handleChange}
                className="hgic-input"
                type={item.type}
                name={item.value}
                value={
                  moleculeInfo[item.value] === null
                    ? ''
                    : moleculeInfo[item.value]
                }
              />
            </label>
          ))}
        </div>
      </div>
    </Modal>
  )
}

const customStyles = {
  overlay: {
    zIndex: '999',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
}
