import React from 'react'
import ReactDOM from 'react-dom/client'

import { Provider } from 'react-redux'
import { ToastContainer } from 'react-toastify'

import App from './App'
import reportWebVitals from './reportWebVitals'
import { store } from 'stores'
import './index.css'
import 'react-tooltip/dist/react-tooltip.css'
import 'react-toastify/dist/ReactToastify.min.css'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
    <ToastContainer
      style={{ width: 'auto' }}
      theme="colored"
      hideProgressBar
      position="top-center"
      closeOnClick
    />
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
