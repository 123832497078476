import React from 'react'

export default function DetailCard({ molecule, fields, title }) {
  return (
    <div className="w-full">
      <h2 className="text-red-500 text-lg font-light mb-2">{title}</h2>
      <div className="text-md grid grid-cols-2 gap-1 gap-x-12">
        {fields.map((field, i) => (
          <React.Fragment key={`${field}-${i}`}>
            <p className="">
              <span className="text-gray-600 font-normal mr-4">
                {field.name}:
              </span>
              <span className="text-black font-semibold">
                {typeof molecule[field.value] === 'number'
                  ? Number(molecule[field.value]).toFixed(2)
                  : molecule[field.value]}
              </span>
            </p>
          </React.Fragment>
        ))}
      </div>
    </div>
  )
}
