import axios from 'axios'

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
  },
})

export const fetchMolecule = async ({ queryKey }) => {
  const id = queryKey[1]
  const { data } = await api.get(`virtualDB/${id}`)
  return data
}

export const fetchUsers = async ({ queryKey }) => {
  const { data } = await api.get('users')
  return data
}
