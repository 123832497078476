import { useState } from 'react'

import Modal from 'react-modal'
import { useSelector, useDispatch } from 'react-redux'

import { api } from 'api'
import MoleculeSelector from 'components/Molecules/MoleculeSelector'
import { resetExports } from 'stores/moleculesSlice'
import { downloadFile, notify } from 'utils'

Modal.setAppElement('#root')

export default function ExportMolecules() {
  const { molecules, user } = useSelector((state) => state)
  const dispatch = useDispatch()
  const [fileType, setFileType] = useState('csv')
  const [isOpen, setIsOpen] = useState(false)

  const handleDownload = async () => {
    const columns = molecules.csvOptions.map((el) => el.value)
    try {
      notify('File download processing, please wait...', 'INFO')
      const { data } = await api.post(
        `virtualDB/download?file_type=${fileType}&group=${user.user.group}`,
        {
          virtual_ids: molecules.selected,
          columns: columns.filter((e) => e !== '*'),
        }
      )
      downloadFile(data, fileType)
      dispatch(resetExports())
      setIsOpen(false)
      notify('File Downloaded Successfully', 'SUCCESS')
    } catch (err) {
      if (err?.response?.data?.detail[0].msg) {
        notify(err?.response?.data?.detail[0].msg, 'ERROR')
      } else {
        notify(err?.response?.statusText, 'ERROR')
      }
    }
  }

  const handleClick = () => {
    setIsOpen(true)
  }

  const closeModal = () => {
    setIsOpen(false)
  }

  const handleFileType = (e) => {
    setFileType(e.target.value)
  }

  return (
    <>
      <button
        className="hover:bg-red-300 rounded-md py-2 px-4 hover:text-white"
        onClick={handleClick}
        type="button"
      >
        Export
      </button>
      <Modal isOpen={isOpen} onRequestClose={closeModal} style={ModalStyle}>
        <h2 className="text-2xl text-center font-semibold text-red-500">
          Export Molecules
        </h2>
        <MoleculeSelector />
        <div className="flex justify-end items-center">
          <label htmlFor="file-selector" className="mr-4">
            Select File Type:
            <select
              onChange={handleFileType}
              name="file-selector"
              value={fileType}
              className="ml-2"
            >
              <option value={null}>--</option>
              <option value="csv">CSV</option>
              <option value="sdf">SDF</option>
            </select>
          </label>
          <button
            className={`${
              molecules?.selected?.length === 0
                ? 'cursor-not-allowed'
                : 'px-4 py-1 mr-2 mt-2 bg-green-300 hover:bg-green-500 hover:text-white rounded-md'
            }`}
            type="button"
            disabled={molecules?.selected?.length === 0}
            onClick={handleDownload}
          >
            Download Selected
          </button>
        </div>
      </Modal>
    </>
  )
}

const ModalStyle = {
  content: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    border: '1px solid #ccc',
    background: '#fff',
    overflow: 'auto',
    WebkitOverflowScrolling: 'touch',
    borderRadius: '4px',
    outline: 'none',
    padding: '50px',
    width: '70%',
    height: '70%',
  },
  overlay: {
    zIndex: '999',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
}
