import { useNavigate } from 'react-router'

import { useDispatch } from 'react-redux'

import { api } from 'api'
import { notify, notifyError } from 'utils'
import { setUser } from 'stores/userSlice'

export default function SignIn() {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const handleSubmit = async (e) => {
    e.preventDefault()

    const formData = new FormData(e.target)
    const username = formData.get('email') ?? ''
    const password = formData.get('password') ?? ''
    const toastID = notify('Loading...', 'LOADING')

    try {
      const { data } = await api.post(
        `/user/${username}/login?password=${password}`
      )
      notify('Success', 'LOADED', toastID, 'success')
      dispatch(setUser(data))
      navigate('/molecules')
    } catch (err) {
      notifyError(err, toastID, 'error')
    }
  }
  return (
    <div className="flex flex-col min-h-screen antialiased">
      <div className="flex flex-row items-center justify-center container mx-auto mt-4 mb-4 text-gray-700 flex-1">
        <div className="w-full max-w-lg flex flex-col items-center">
          <img
            className="mb-6"
            src={`${process.env.PUBLIC_URL}/images/logo.png`}
            alt="logo"
          />
          <div className="w-full max-w-sm p-4 bg-white border border-gray-200 rounded-lg shadow lg:p-6">
            <form className="space-y-6" action="POST" onSubmit={handleSubmit}>
              <h5 className="text-xl font-medium text-gray-900 text-center">
                Login Proton
              </h5>
              <div>
                <label
                  htmlFor="email"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Your email
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  placeholder="email@1910genetics.com"
                  required
                />
              </div>
              <div>
                <label
                  htmlFor="password"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Your password
                </label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  required
                />
              </div>
              <button
                type="submit"
                className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
              >
                Login
              </button>
              <div className="text-sm font-medium text-gray-500 text-center">
                Lost password? Contact swe@1910genetics.com
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
