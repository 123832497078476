import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { api } from 'api'

const initialState = {
  molecules: {},
  status: 'idle',
  error: null,
  query: '{}',
  skip: 0,
  limit: 300,
  criteria: '_id',
  search: '',
  selected: [],
  csvOptions: [],
}

export const fetchMolecules = createAsyncThunk(
  'molecules/fetchMolecules',
  async (role, thunkAPI) => {
    const { molecules } = thunkAPI.getState()

    const { data } = await api.get('virtualDB/get/board', {
      params: {
        skip: molecules.skip,
        limit: molecules.limit,
        query: molecules.query,
        role,
      },
    })
    return data
  }
)

// export const addNewPost = createAsyncThunk(
//   'posts/addNewPost',
//   async (initialPost) => {
//     const response = await api.post('/fakeApi/posts', initialPost)
//     return response.data
//   }
// )

const moleculesSlice = createSlice({
  name: 'molecules',
  initialState,
  reducers: {
    setCriteria: (state, action) => {
      state.criteria = action.payload
    },
    setSearch: (state, action) => {
      state.search = action.payload
    },
    addToSelected: (state, action) => {
      state.selected = [...state.selected, action.payload]
    },
    removeFromSelected: (state, action) => {
      state.selected = state.selected.filter((ids) => ids !== action.payload)
    },
    resetExports: (state, action) => {
      state.selected = []
      state.csvOptions = []
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchMolecules.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(fetchMolecules.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.molecules = action.payload
      })
      .addCase(fetchMolecules.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })
  },
})

export const {
  setCriteria,
  setSearch,
  refetch,
  addToSelected,
  removeFromSelected,
  resetExports,
} = moleculesSlice.actions

export default moleculesSlice.reducer
