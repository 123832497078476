/* eslint-disable react/no-array-index-key, camelcase, no-nested-ternary */

import { useState } from 'react'
import { useSelector } from 'react-redux'
import Select from 'react-select'

import { api } from 'api'
import { batchFields } from 'data'
import { notify, notifyError } from 'utils'

export default function BatchForm({ moleculeID, refetch, setShowBatchForm }) {
  const { user } = useSelector((state) => state.user)
  const [chiral, setChiral] = useState([])
  const [batchInfo, setBatchInfo] = useState({
    synthesis_quantity: '',
    state: '',
    color: '',
    chirality: '',
    formula: '',
    eln_reference: '',
    formula_weight: '',
    vendor: '',
    vendor_id: '',
    barcode_1: '',
    barcode_2: '',
    barcode_3: '',
    amount_1: '',
    amount_2: '',
    amount_3: '',
    cro_barcode_1: '',
    cro_barcode_2: '',
    cro_barcode_3: '',
    cro_amount_1: '',
    cro_amount_2: '',
    cro_amount_3: '',
    salt: '',
    hplc: '',
    nmr: '',
    shipping_number: '',
    notes: '',
  })

  const handleChange = (e) => {
    const { name, value } = e.target
    setBatchInfo({
      ...batchInfo,
      [name]: value,
    })
  }

  const handleSelect = (value, event) => {
    setChiral(value)
  }

  const handleSave = async (e) => {
    e.preventDefault()
    const {
      barcode_1,
      barcode_2,
      barcode_3,
      amount_1,
      amount_2,
      amount_3,
      cro_barcode_1,
      cro_barcode_2,
      cro_barcode_3,
      cro_amount_1,
      cro_amount_2,
      cro_amount_3,
      chirality,
      ...rest
    } = batchInfo

    const vial_code = `${barcode_1.trim()},${barcode_2.trim()},${barcode_3.trim()}`
    const vial_amount = `${amount_1},${amount_2},${amount_3}`

    const cro_vial_code = `${cro_barcode_1.trim()},${cro_barcode_2.trim()},${cro_barcode_3.trim()}`
    const cro_vial_amount = `${cro_amount_1},${cro_amount_2},${cro_amount_3}`

    const batchDetails = {
      vial_code,
      vial_amount,
      cro_vial_code,
      cro_vial_amount,
      chirality: chiral.map((el) => el.value).join(', '),
      ...rest,
    }
    try {
      await api.post(
        `/virtualDB/add/batch-details/${moleculeID}?user=${user.first_name} ${user.last_name}`,
        batchDetails
      )
      notify('Compound Batch Details Successfully Added', 'SUCCESS')
      setShowBatchForm((prevState) => !prevState)
      refetch()
    } catch (err) {
      notifyError(err)
    }
  }

  return (
    <form onSubmit={handleSave} className="my-4">
      <div className="mb-2">
        <button
          type="button"
          className="btn hover:text-red-500"
          onClick={() => setShowBatchForm((prevState) => !prevState)}
        >
          Cancel
        </button>
        <button type="submit" className="btn-green text-white">
          Save
        </button>
      </div>

      <div className="grid grid-cols-3 gap-2 w-full">
        {batchFields.map((item, i) => (
          <label
            key={`${item.name}-${i}`}
            className="block text-sm text-gray-600"
          >
            {item.name}
            {item.req && <span className="text-red-500">*</span>}

            {item.type === 'select' ? (
              <select
                name={item.value}
                onChange={handleChange}
                className="hgic-input"
                value={batchInfo[item.value]}
                required={item.req}
              >
                <option value="">---</option>
                {item.options.map((option) => (
                  <option key={`1${option}`} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            ) : item.type === 're-select' ? (
              <Select
                value={chiral}
                className="mt-2"
                options={item.options}
                isMulti
                isSearchable
                onChange={handleSelect}
              />
            ) : item.type === 'textarea' ? (
              <textarea
                name={item.value}
                className="hgic-input col-span-2"
                onChange={handleChange}
                value={batchInfo[item.value]}
              ></textarea>
            ) : (
              <input
                className="hgic-input"
                name={item.value}
                type={item.type}
                required={item.req}
                value={batchInfo[item.value]}
                onChange={handleChange}
              />
            )}
          </label>
        ))}
      </div>
      <label htmlFor="notes" className="block text-sm text-gray-600">
        Notes
        <textarea
          name="notes"
          onChange={handleChange}
          value={batchInfo.notes}
          className="hgic-input w-6/12"
          cols="30"
          rows="10"
        ></textarea>
      </label>
    </form>
  )
}
