import { useSelector } from 'react-redux'
import { api } from 'api'
import { notify, notifyError } from 'utils'

export default function CandidTable({ molecule, refetch }) {
  const { user } = useSelector((state) => state.user)

  const PREDICTION_TABLE = {
    LogD: molecule?.candid_logd,
    LogS: molecule?.candid_logs,
    PKA: molecule?.candid_pka,
    'Caco-2': molecule?.candid_caco,
    MPO: molecule?.candid_mpo,
    BBBP: molecule?.candid_bbbp,
    HLM: molecule?.candid_hlm,
  }

  const updatePredictions = async () => {
    const toastID = notify('Updating...', 'LOADING')
    try {
      await api.post(`/virtualDB/get/candid/${molecule._id}`)
      refetch()
      notify('Success...', 'LOADED', toastID, 'success')
    } catch (err) {
      notifyError(err, toastID, 'error')
    }
  }

  return (
    <>
      <div className="flex">
        <h2 className="text-red-500 text-lg font-light">Candid Table</h2>
        {user.role === 'ADMIN' && (
          <button
            type="button"
            onClick={updatePredictions}
            className="hover:text-blue-400 ml-2"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
              />
            </svg>
          </button>
        )}
      </div>
      <div className="relative overflow-x-auto">
        <table className="w-full text-sm text-left text-gray-500">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
              {Object.keys(PREDICTION_TABLE).map((key) => (
                <th key={key} scope="col" className="px-6 py-3">
                  {key}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr className="bg-white border-b">
              {Object.values(PREDICTION_TABLE).map((predictions) => (
                <td className="px-6 py-4">
                  {Number(
                    predictions[predictions.length - 1]?.prediction
                  ).toFixed(2)}
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>
    </>
  )
}
