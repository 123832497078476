/* eslint-disable no-alert */
import { useState } from 'react'
import { useSelector } from 'react-redux'

import Modal from 'react-modal'

import { api } from 'api'
import { notify, notifyError } from 'utils'

Modal.setAppElement('#root')

export default function ShareMolecule({ refetch, moleculeID }) {
  const { user } = useSelector((state) => state.user)
  const [showModal, setShowModal] = useState(false)
  const [sharedWith, setSharedWith] = useState('')

  const handleChange = (e) => {
    setSharedWith(e.target.value)
  }

  const handleShare = async () => {
    try {
      if (
        window.confirm(
          `Are you sure you want to ${
            sharedWith === '' ? 'unshare' : 'share'
          } this molecule?`
        )
      ) {
        await api.post(
          `virtualDB/share/${moleculeID}?share_with=${sharedWith}&user=${user.first_name} ${user.last_name}`
        )
        notify(`Compound ${sharedWith === '' ? 'unshared' : 'shared'}`, 'INFO')
        refetch()
        setShowModal((prevState) => !prevState)
      }
    } catch (err) {
      notifyError(err)
    }
  }

  return (
    <>
      <button
        type="button"
        className="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-white border-t border-b border-gray-200 hover:bg-gray-100 hover:text-blue-500 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700"
        onClick={() => setShowModal((prevState) => !prevState)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-4 h-4 mr-2"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M7.217 10.907a2.25 2.25 0 100 2.186m0-2.186c.18.324.283.696.283 1.093s-.103.77-.283 1.093m0-2.186l9.566-5.314m-9.566 7.5l9.566 5.314m0 0a2.25 2.25 0 103.935 2.186 2.25 2.25 0 00-3.935-2.186zm0-12.814a2.25 2.25 0 103.933-2.185 2.25 2.25 0 00-3.933 2.185z"
          />
        </svg>
        Share
      </button>
      {showModal && (
        <Modal
          isOpen={showModal}
          onRequestClose={() => setShowModal((prevState) => !prevState)}
          style={customStyles}
        >
          <div className="flex flex-col">
            <label htmlFor="Shared With">
              Select CROs from the list to share the compound with:
              <select
                className="hgic-input"
                onChange={handleChange}
                name="Shared With"
                value={sharedWith}
                required
              >
                <option value="">---</option>
                <option value="">Unshare</option>
                <option value="Aurigene">Aurigene</option>
                <option value="Aragen">Aragen</option>
              </select>
            </label>

            <div className="mt-4 flex justify-end text-white">
              <button
                type="button"
                className="text-black btn hover:text-red-400"
                onClick={() => setShowModal((prevState) => !prevState)}
              >
                Cancel
              </button>

              <button type="submit" className="btn-blue" onClick={handleShare}>
                Share
              </button>
            </div>
          </div>
        </Modal>
      )}
    </>
  )
}

const customStyles = {
  overlay: {
    zIndex: '999',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    innerWidth: '50px',
  },
  content: {
    position: 'relative',
    top: '50%',
    left: '50%',
    width: '500px',
    height: '200px',
    transform: 'translate(-50%, -50%)',
    background: '#fff',
    overflow: 'auto',
    WebkitOverflowScrolling: 'touch',
    borderRadius: '4px',
    outline: 'none',
    padding: '20px',
  },
}
