/* eslint-disable no-plusplus */
import { useState } from 'react'

import { useSelector, useDispatch } from 'react-redux'
import { addToSelected, removeFromSelected } from 'stores/moleculesSlice'

import { notify, stripIDs } from 'utils/index'

export default function MoleculeSelector() {
  const dispatch = useDispatch()
  const { molecules } = useSelector((state) => state)
  const [selectedMolecules, setSelectedMolecules] = useState('')
  const [moleculeRange, setMoleculeRange] = useState({ min: 0, max: 0 })

  const addSelected = () => {
    stripIDs(selectedMolecules).forEach((mol) => {
      if (molecules.selected.includes(Number(mol))) {
        return
      } else {
        if (isNaN(Number(mol))) {
          return
        }
        dispatch(addToSelected(Number(mol)))
        setSelectedMolecules('')
      }
    })
  }

  const removeSelected = () => {
    stripIDs(selectedMolecules).forEach((mol) => {
      if (molecules.selected.includes(Number(mol))) {
        dispatch(removeFromSelected(Number(mol)))
        setSelectedMolecules('')
      }
    })
  }

  const handleMoleculeRange = (e) => {
    const { name, value } = e.target
    setMoleculeRange({ ...moleculeRange, [name]: value })
  }
  const handleRangeSelector = () => {
    const min = Number(moleculeRange.min)
    const max = Number(moleculeRange.max)
    if (min > max || (max && min <= 0)) {
      notify('Invalid Range', 'ERROR')
      return
    }
    for (let i = min; i <= max; i++) {
      dispatch(addToSelected(i))
    }
    setMoleculeRange({ min: 0, max: 0 })
  }

  return (
    <>
      <div className="mt-2">
        <label
          htmlFor="molecule select"
          className="mt-2 tracking-wide text-lg font-light"
        >
          Select Molecules
        </label>
        <p className="mt-2 text-xs font-extralight">
          Please provide a list of IDs separated by spaces when adding and
          removing molecules from the list.
          <strong>Example: 14672 14671 1910V150092 1910V150095</strong>
        </p>
        <textarea
          onChange={(e) => setSelectedMolecules(e.target.value)}
          className="mt-2 block w-full p-2 text-lg leading-5 border-gray-200 border-2 rounded-md border-solid"
          name="molecule select"
          cols="30"
          rows="2"
          value={selectedMolecules}
        />
        <div>
          <button
            className="px-4 py-1 mr-2 mt-2 bg-green-300 hover:bg-green-500 hover:text-white rounded-md"
            type="button"
            onClick={addSelected}
            disabled={selectedMolecules === ''}
          >
            Add
          </button>
          <button
            className="px-4 py-1 mr-2 mt-2 bg-red-300 hover:bg-red-500 hover:text-white rounded-md"
            type="button"
            onClick={removeSelected}
          >
            Remove
          </button>
        </div>
      </div>

      <div className="mt-2">
        <h3>Range Selector:</h3>
        <p className="mt-2 text-xs font-extralight">
          Enter a minimum and maximum Virtual ID to select the range of
          molecules. Enter the same range to deselect the molecules.
        </p>
        <div>
          <label htmlFor="min" className="mr-2">
            Min:
            <input
              type="number"
              name="min"
              className="ml-2 border-gray-200 border-2 rounded-md border-solid"
              value={moleculeRange.min}
              onChange={handleMoleculeRange}
            />
          </label>
          <label htmlFor="max" className="mr-2">
            Max:
            <input
              type="number"
              name="max"
              className="ml-2 border-gray-200 border-2 rounded-md border-solid"
              value={moleculeRange.max}
              onChange={handleMoleculeRange}
            />
          </label>
          <button
            type="button"
            onClick={handleRangeSelector}
            className="px-4 py-1 bg-green-300 hover:bg-green-500 hover:text-white rounded-md"
          >
            Select
          </button>
        </div>
      </div>
      <p className="mt-4 tracking-wide text-lg font-light">
        Selected Molecules:{' '}
        <span className="text-red-400 font-light">
          {molecules?.selected?.length}
        </span>
      </p>
      <ol className="mt-2 flex flex-wrap">
        {molecules?.selected?.map((item) => (
          <li
            className="tracking-tight mx-2 p-2 text-center text-red-500 rounded-md cursor-pointer select-none"
            onClick={() => dispatch(removeFromSelected(item))}
            key={item}
          >
            {item}
          </li>
        ))}
      </ol>
    </>
  )
}
