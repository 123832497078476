import { useQuery } from '@tanstack/react-query'

import { fetchUsers } from 'api'
import UsersTable from 'components/admin/UsersTable'
import NewUserModal from 'components/admin/NewUserModal'

export default function Admin() {
  const results = useQuery(['users'], fetchUsers)

  if (results.isLoading) return <h1>Loading...</h1>
  if (results.isError) return <h1>Error...</h1>

  return (
    <div>
      <h1>Admin Panel</h1>
      <div>
        <NewUserModal refetch={results?.refetch} />
        <UsersTable users={results?.data} />
      </div>
    </div>
  )
}
