import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
  Navigate,
} from 'react-router-dom'

import Layout from 'components/layouts'
import Molecules from 'pages/molecules'
import MoleculeDetails from 'pages/molecules/molecule'
import SignIn from 'components/auth/SignIn'
import PrivateRoutes from 'components/auth/PrivateRoutes'
import Admin from 'pages/admin'

const queryClient = new QueryClient({})

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route element={<Layout />}>
        <Route
          element={<PrivateRoutes allowedRoles={['USER', 'ADMIN', 'CRO']} />}
        >
          <Route path="/molecules" element={<Molecules />} />
          <Route path="/molecules/:moleculeID" element={<MoleculeDetails />} />
        </Route>
        <Route element={<PrivateRoutes allowedRoles={['ADMIN']} />}>
          <Route path="/admin" element={<Admin />} />
        </Route>
        <Route path="*" element={<Navigate to="/molecules" replace />} />
      </Route>
      <Route path="/login" element={<SignIn />} />
      <Route path="/unauthorized" element={<>Not authorized</>} />
    </>
  )
)

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
    </QueryClientProvider>
  )
}

export default App
