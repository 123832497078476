import { useState } from 'react'

import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'

import { api, fetchMolecule } from 'api'
import { notify, notifyError } from 'utils'
import { batchDisplayFields, makeFields, ALLOWED_USERS } from 'data'
import BatchForm from 'components/Molecules/BatchForm'
import AdditionalDetails from 'components/Molecules/AdditionalDetails'
import EditMoleculeModal from 'components/Molecules/EditMoleculeModal'
import DetailCard from 'components/Molecules/DetailCard'
import ShareMolecule from 'components/Molecules/ShareMolecule'

export default function MoleculeDetails() {
  const { moleculeID } = useParams()
  const { user } = useSelector((state) => state.user)
  const [showBatchForm, setShowBatchForm] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)
  const [viewDetails, setViewDetails] = useState(false)
  const [files, setFiles] = useState(null)
  const results = useQuery(['molecule', moleculeID], fetchMolecule)

  const AddBatch = () => {
    setShowBatchForm((prevState) => !prevState)
  }
  const editMolecule = () => {
    setShowEditModal((prevState) => !prevState)
  }

  const archiveMolecule = async () => {
    try {
      if (window.confirm('Are you sure you want to archive this molecule?')) {
        await api.post(`/virtualDB/archive/${moleculeID}`)
        notify('Compound Archived', 'INFO')
      }
    } catch (err) {
      notifyError(err)
    }
  }
  const registerBatch = async (id, batch) => {
    const toastID = notify('Loading...')
    try {
      await api.post(
        `/virtualDB/register/batch-details/${id}?batch=${batch}&user=${user.first_name} ${user.last_name}`
      )
      notify(`Registered:${id}`, 'LOADED', toastID, 'success')
      results.refetch()
    } catch (err) {
      notifyError(err, toastID, 'error')
    }
  }

  const loadFile = (e) => {
    const content = e.target
    if (content.files.length === 0) return
    setFiles(content.files[0])
  }
  const uploadToELN = async (e, id) => {
    e.preventDefault()

    const toastID = notify('Processing...', 'LOADING')
    const formData = new FormData()
    formData.append('file', files)

    try {
      await api.post(`/cdd/file?id=${id}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      api.post(
        `/virtualDB/add-log/${moleculeID}?user=${user.first_name} ${user.last_name}&description=uploaded ${files.name}`
      )
      notify(`${files.name} uploaded`, 'LOADED', toastID, 'success')
    } catch (error) {
      notifyError(error, toastID, 'error')
    }
  }

  if (results.isLoading) {
    return <h1>Loading...</h1>
  }

  if (results.isError) {
    return <h1>Error...</h1>
  }

  return (
    <>
      {/* Button Layouts */}
      {user.role !== 'CRO' && (
        <div className="inline-flex rounded-md shadow-sm" role="group">
          <button
            type="button"
            onClick={editMolecule}
            className="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-l-lg hover:bg-gray-100 hover:text-green-500 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4 mr-2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
              />
            </svg>
            Edit
          </button>
          <ShareMolecule refetch={results.refetch} moleculeID={moleculeID} />
          <button
            type="button"
            onClick={archiveMolecule}
            className="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-r-md hover:bg-gray-100 hover:text-orange-500 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4 mr-2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M20.25 7.5l-.625 10.632a2.25 2.25 0 01-2.247 2.118H6.622a2.25 2.25 0 01-2.247-2.118L3.75 7.5m6 4.125l2.25 2.25m0 0l2.25 2.25M12 13.875l2.25-2.25M12 13.875l-2.25 2.25M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z"
              />
            </svg>
            Park
          </button>
        </div>
      )}

      <>
        {/* Molecule Information & Structure Display */}
        <div className="flex items-baseline justify-between">
          <h2 className="text-red-500 text-2xl font-bold">
            Molecule Information
          </h2>
        </div>
        <div className="mb-4 flex items-center justify-center">
          <div className="flex justify-center items-center w-6/12">
            <img
              className="-mt-1 select-none pointer-events-none w-88"
              src={`${
                process.env.REACT_APP_API_URL
              }/image?smi=${encodeURIComponent(results.data.smiles)}`}
              loading="lazy"
              alt=""
            />
          </div>

          {/* display property information */}
          <div className="flex flex-col items-center justify-center w-6/12">
            <DetailCard molecule={results.data} fields={makeFields} />
            <p className="text-sm font-light mt-4 select-all bg-gray-100 rounded-sm p-2">
              {results.data.smiles}
            </p>
          </div>
        </div>

        {user.role !== 'CRO' && (
          <p
            onClick={() => setViewDetails((prevState) => !prevState)}
            className="cursor-pointer font-light uppercase text-blue-500 hover:text-blue-300 inline"
            role="presentation"
          >
            View details
          </p>
        )}

        {viewDetails && (
          <AdditionalDetails
            molecule={results.data}
            refetch={results.refetch}
          />
        )}

        <div className="w-full h-px bg-gray-200 my-8" />

        <div className="">
          <div className="flex items-center">
            <h2 className="text-red-500 text-lg font-light mb-2 mr-4">
              Synthesis Results
            </h2>
            {ALLOWED_USERS.includes(user.role) && (
              <button
                type="button"
                className="btn-blue text-white"
                onClick={AddBatch}
              >
                Add Batch
              </button>
            )}
          </div>
          {showBatchForm && (
            <BatchForm
              moleculeID={moleculeID}
              refetch={results.refetch}
              setShowBatchForm={setShowBatchForm}
            />
          )}

          {/* work on styling and edit capability */}
          {results.data.batch_details.map((details, i) => (
            <div key={i} className="my-4">
              {details.registered === 'False' &&
                results.data.stage === 'Registered' &&
                user.role !== 'CRO' && (
                  <button
                    type="button"
                    className="btn-yellow"
                    onClick={() =>
                      registerBatch(results.data._id, details.batch)
                    }
                  >
                    Register
                  </button>
                )}
              <div
                className={`text-md grid grid-cols-2 my-2 gap-1 gap-x-12 shadow-sm font-light p-2 rounded-lg ${
                  details.registered === 'False' &&
                  'border-t-2 border-orange-300'
                }`}
              >
                {batchDisplayFields.map((field) => (
                  <div key={`${details.batch}-${field.name}`}>
                    <p>
                      <span className="text-gray-600 font-normal mr-4">
                        {field.name}:
                      </span>
                      <span className="text-black font-medium">
                        {field.name === 'ELN' ? (
                          <a
                            href={details[field.value]}
                            target="_blank"
                            className="text-blue-400 hover:text-blue-200"
                            rel="noopener noreferrer"
                          >
                            Link
                          </a>
                        ) : (
                          details[field.value]
                        )}
                      </span>
                    </p>
                  </div>
                ))}
              </div>
              <form
                action=""
                encType="multipart/form-data"
                onSubmit={(e) => uploadToELN(e, details.eln_id)}
              >
                <div className="relative w-full flex text-sm items-baseline">
                  Upload to ELN Page
                  <input
                    type="file"
                    id="small_size"
                    name="eln-file"
                    className="ml-2 block w-72 z-20 text-sm text-gray-900 bg-gray-50 border-l-gray-100 border-l-2 border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                    onChange={(e) => loadFile(e)}
                  />
                  <button
                    type="submit"
                    className="p-[3.5px] text-sm font-medium text-white bg-blue-700 rounded-r-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300"
                  >
                    Upload
                  </button>
                </div>
              </form>
            </div>
          ))}
        </div>

        <EditMoleculeModal
          isOpen={showEditModal}
          closeModal={setShowEditModal}
          refetch={results.refetch}
          molecule={results.data}
        />
      </>
    </>
  )
}
