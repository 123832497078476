import { useState } from 'react'

import Modal from 'react-modal'

import { api } from 'api'
import { notify, notifyError } from 'utils'

Modal.setAppElement('#root')

export default function UserDropDown({ user }) {
  const [isOpen, setIsOpen] = useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault()
    const formData = new FormData(e.target)
    const updates = {
      first_name: formData.get('first_name') ?? '',
      last_name: formData.get('last_name') ?? '',
      email: user.email,
      group_email: formData.get('group_email') ?? '',
      role: formData.get('role') ?? '',
      group: formData.get('group') ?? '',
      password: formData.get('password') ?? '',
    }

    const toastID = notify('Loading...', 'LOADING')

    try {
      await api.put('/user/update', updates)
      notify('Success', 'LOADED', toastID, 'success')
    } catch (err) {
      notifyError(err, toastID, 'error')
    }
  }
  return (
    <>
      <td
        className="px-6 py-4 cursor-pointer hover:text-blue-400"
        onClick={() => setIsOpen((prevState) => !prevState)}
      >
        Edit User
      </td>
      {isOpen && (
        <Modal
          isOpen={isOpen}
          onRequestClose={() => setIsOpen((prevState) => !prevState)}
          style={customStyles}
        >
          <form action="POST" onSubmit={handleSubmit}>
            <div className="flex mb-2">
              <div className="w-6/12 mr-2">
                <label
                  htmlFor="first_name"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  First Name
                </label>
                <input
                  type="text"
                  name="first_name"
                  className="flow-input"
                  defaultValue={user.first_name}
                />
              </div>

              <div className="w-6/12 ml-2">
                <label
                  htmlFor="last_name"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Last Name
                </label>
                <input
                  type="text"
                  name="last_name"
                  className="flow-input"
                  defaultValue={user.last_name}
                />
              </div>
            </div>

            <div className="flex mb-2">
              <div className="w-6/12 mr-2">
                <label
                  htmlFor="email"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Email
                </label>
                <input
                  type="email"
                  name="email"
                  className="flow-input"
                  disabled
                  defaultValue={user.email}
                />
              </div>
              <div className="w-6/12 mr-2">
                <label
                  htmlFor="group_email"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Group Email
                </label>
                <input
                  type="email"
                  name="group_email"
                  className="flow-input"
                  defaultValue={user.group_email}
                />
              </div>
              <div className="w-6/12 ml-2">
                <label
                  htmlFor="password"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Change Password
                </label>
                <input type="password" name="password" className="flow-input" />
              </div>
            </div>

            <div className="flex mb-2">
              <div className="w-6/12 mr-2">
                <label
                  htmlFor="role"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Role
                </label>
                <select
                  name="role"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  defaultValue={user.role}
                >
                  <option value="USER">User</option>
                  <option value="CONTRIBUTOR">Contributor</option>
                  <option value="MEDCHEM">MedChem</option>
                  <option value="ADMIN">Admin</option>
                  <option value="CRO">Cro</option>
                </select>
              </div>

              <div className="w-6/12 ml-2">
                <label
                  htmlFor="group"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Group
                </label>
                <select
                  name="group"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  defaultValue={user.group}
                >
                  <option value="1910 Genetics">1910 Genetics</option>
                  <option value="Aurigene">Aurigene</option>
                  <option value="Aragen">Aragen</option>
                </select>
              </div>
            </div>

            <button type="submit" className="text-white btn-blue w-full my-2">
              Update User
            </button>
          </form>
        </Modal>
      )}
    </>
  )
}
const customStyles = {
  overlay: {
    zIndex: '999',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    innerWidth: '50px',
  },
  content: {
    position: 'relative',
    top: '50%',
    left: '50%',
    width: '600px',
    height: '325px',
    transform: 'translate(-50%, -50%)',
    background: '#fff',
    overflow: 'auto',
    WebkitOverflowScrolling: 'touch',
    borderRadius: '4px',
    outline: 'none',
    padding: '20px',
  },
}
