/* eslint-disable react/no-array-index-key, no-underscore-dangle, no-shadow */

import { DragDropContext, Draggable } from 'react-beautiful-dnd'
import { Tooltip } from 'react-tooltip'

import { useSelector } from 'react-redux'

import { api } from 'api'
import MoleculeCard from 'components/Molecules/MoleculeCard'
import Droppable from 'components/Molecules/Droppable'
import { notify, notifyError } from 'utils'
import { RESTRICTED_STAGES, ALLOWED_USERS } from 'data'

export default function MoleculeTracking({ data, refetch }) {
  const { user } = useSelector((state) => state.user)
  const onDragEnd = async (result) => {
    const { destination, draggableId, source } = result
    if (!destination) {
      notify('Select a stage', 'INFO')
      return
    }

    if (source.droppableId === destination.droppableId) {
      return
    }

    const stage = destination.droppableId

    if (RESTRICTED_STAGES.includes(stage) && user.role === 'CRO') {
      notify('You do not have the required permissions.', 'WARN')
      return
    }

    if (
      RESTRICTED_STAGES.includes(stage) &&
      !ALLOWED_USERS.includes(user.role)
    ) {
      notify('You do not have the required permissions.', 'WARN')
      return
    }

    const toastID = notify('Processing...', 'LOADING')
    try {
      await api.post(
        `/virtualDB/set/stage/${Number(draggableId)}?stage=${stage}&user=${
          user.first_name
        } ${user.last_name}`
      )
      refetch()
      notify('Stage updated', 'LOADED', toastID, 'success')
    } catch (err) {
      notifyError(err, toastID, 'error')
    }
  }

  return (
    <>
      <div className="w-full p-1 my-2 bg-gradient-to-r from-gray-300 to-green-500 w-12/12" />
      <div className="flex overflow-auto">
        <DragDropContext onDragEnd={(result) => onDragEnd(result)}>
          {Object.entries(data).map(([id, col]) => (
            <div key={id} className="select-none flex flex-col items-center">
              {col.name === 'Design' ? (
                <h2 className="flex">
                  Design
                  <div data-tip data-for="new-molecule">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="w-6 h-6 text-red-200 cursor-pointer hover:text-red-500"
                      // onClick={() => dbStore.setIsDesign()}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M13.5 16.875h3.375m0 0h3.375m-3.375 0V13.5m0 3.375v3.375M6 10.5h2.25a2.25 2.25 0 002.25-2.25V6a2.25 2.25 0 00-2.25-2.25H6A2.25 2.25 0 003.75 6v2.25A2.25 2.25 0 006 10.5zm0 9.75h2.25A2.25 2.25 0 0010.5 18v-2.25a2.25 2.25 0 00-2.25-2.25H6a2.25 2.25 0 00-2.25 2.25V18A2.25 2.25 0 006 20.25zm9.75-9.75H18a2.25 2.25 0 002.25-2.25V6A2.25 2.25 0 0018 3.75h-2.25A2.25 2.25 0 0013.5 6v2.25a2.25 2.25 0 002.25 2.25z"
                      />
                    </svg>
                    <Tooltip id="new-molecule" place="top" effect="float">
                      New Molecule
                    </Tooltip>
                  </div>
                </h2>
              ) : (
                <h2>{col.name}</h2>
              )}
              <div className="mx-2 flex w-52">
                <Droppable droppableId={id}>
                  {(provided, snapshot) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      className={`select-none w-56 h-72 min-h-screen mb-4 ${
                        snapshot.isDraggingOver ? 'bg-red-50' : ''
                      }`}
                    >
                      {col.molecules.map((item, index) => (
                        <Draggable
                          key={String(item._id)}
                          draggableId={String(item._id)}
                          index={index}
                        >
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className=""
                              style={{
                                ...provided.draggableProps.style,
                              }}
                            >
                              <MoleculeCard molecule={item} />
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </div>
            </div>
          ))}
        </DragDropContext>
      </div>
    </>
  )
}
