import Header from 'components/layouts/Header'
import Footer from 'components/layouts/Footer'
import { Outlet } from 'react-router-dom'

export default function Layout({ children }) {
  return (
    <div className="flex flex-col min-h-screen antialiased">
      <Header />
      <div className="container mx-auto mt-4 mb-4 text-gray-700 flex-1">
        <Outlet />
      </div>
      <Footer />
    </div>
  )
}
