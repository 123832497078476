import { toast } from 'react-toastify'

/**
 * Notification function to display Statuses uses 3rd party react-toastify package
 *
 * @param {String} message message to display
 * @param {String} status indicates notification color INFO|WARN|SUCCESS|ERROR
 */
export const notify = (message, status, toast_id = null, type = 'default') => {
  if (status === 'INFO') {
    toast.info(message)
  }
  if (status === 'WARN') {
    toast.warn(message)
  }
  if (status === 'SUCCESS') {
    toast.success(message)
  }
  if (status === 'ERROR') {
    toast.error(message)
  }
  if (status === 'LOADING') {
    const toastID = toast.loading(message)
    return toastID
  }
  if (status === 'LOADED') {
    toast.update(toast_id, {
      render: message,
      type,
      isLoading: false,
      autoClose: 5000,
      closeOnClick: true,
    })
  }
}

export const notifyError = (err, toast_id = null, type = 'error') => {
  if (err?.response?.data?.detail) {
    const { detail } = err.response.data
    if (typeof detail === 'object') {
      const { loc, msg } = detail[0]
      if (loc === undefined || msg === undefined) {
        if (!toast_id) {
          notify(detail[0], 'ERROR')
        } else {
          notify(detail[0], 'LOADED', toast_id, 'error')
        }
      } else {
        const query = loc.reduce((A, B) => `${A}: ${B}`, '')
        if (!toast_id) {
          notify(`${query}, ${msg}`, 'ERROR')
        } else {
          notify(`${query}, ${msg}`, 'LOADED', toast_id, 'error')
        }
      }
    } else if (typeof detail === 'string') {
      if (!toast_id) {
        notify(detail, 'ERROR')
      } else {
        notify(detail, 'LOADED', toast_id, 'error')
      }
    }
  } else {
    if (!toast_id) {
      notify('Something went wrong!', 'ERROR')
    } else {
      notify('Something went wrong!', 'LOADED', toast_id, 'error')
    }
  }
}

export const stripIDs = (selectedMolecules) => {
  return selectedMolecules.replace(/\n/g, ' ').replace(/1910V/gi, '').split(' ')
}

export const downloadFile = (data, fileType) => {
  // Creates an <a> tag and a new instance of a Blob to place CSV contents
  const downloadLink = document.createElement('a')
  const blob = new Blob([data])
  const url = URL.createObjectURL(blob)

  downloadLink.href = url
  // Names the CSV file based on the parameters and includes the date
  downloadLink.download = `exports_${new Date()
    .toISOString()
    .slice(0, 10)}.${fileType}`

  // Appends the url to the <a> tag and clicks link to download
  document.body.appendChild(downloadLink)
  downloadLink.click()

  // removes unused <a> tag from the document body
  document.body.removeChild(downloadLink)
}
