import { useEffect } from 'react'

import { useSelector, useDispatch } from 'react-redux'

import ExportMolecules from 'components/Molecules/ExportMolecules'
import MoleculeTracking from 'components/Molecules/MoleculeTracking'
import { fetchMolecules } from 'stores/moleculesSlice'

export default function Molecules() {
  const { molecules, user } = useSelector((state) => state)
  const dispatch = useDispatch()

  const refetch = () => {
    if (user.user.group !== undefined) {
      dispatch(fetchMolecules(user.user.group))
    }
  }

  useEffect(() => {
    if (user.user.group !== undefined) {
      dispatch(fetchMolecules(user.user.group))
    }
  }, [dispatch, user])

  return (
    <div>
      <ExportMolecules />
      <MoleculeTracking data={molecules.molecules} refetch={refetch} />
    </div>
  )
}
