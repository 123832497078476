import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { useSelector } from 'react-redux'
import { Navigate, Outlet, useLocation } from 'react-router-dom'

import { setStateUser } from 'stores/userSlice'

export default function PrivateRoutes({ allowedRoles }) {
  const dispatch = useDispatch()
  const location = useLocation()
  useEffect(() => {
    if (localStorage.getItem('proton-user')) {
      dispatch(setStateUser())
    }
  }, [dispatch])

  const { user } = useSelector((state) => state.user)
  const localUser = JSON.parse(localStorage.getItem('proton-user'))

  return allowedRoles.includes(localUser?.role) ? (
    <Outlet />
  ) : localUser?.authenticated ? (
    <Navigate to="/unauthorized" state={{ from: location }} replace />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  )
}
