export const RESTRICTED_STAGES = ['Approved', 'Registered']

export const ALLOWED_USERS = ['ADMIN', 'MEDCHEM', 'CRO']

const purity = ['>98', '>95', '>90', '>85', '<85']

const compoundColor = [
  'white',
  'off-white',
  'cream',
  'pink',
  'brown',
  'yellow',
  'green',
  'colorless',
]
const compoundSalt = [
  'free base',
  'Unknown Salt',
  'Hydrochloride',
  'Trifluoroacetic acid',
  'Oxalate',
  'Hydrobromide',
  'SODIUM',
  'Formate',
]

const chirality = [
  { value: 'Achiral', label: 'Achiral' },
  { value: 'Racemic', label: 'Racemic' },
  {
    value: 'Single enantiomer of known absolute configuration',
    label: 'Single enantiomer of known absolute configuration',
  },
  {
    value: 'Single enantiomer of unknown absolute configuration',
    label: 'Single enantiomer of unknown absolute configuration',
  },
  {
    value: 'Single diastereomer of known absolute configuration',
    label: 'Single diastereomer of known absolute configuration',
  },
  {
    value: 'Single diastereomer of unknown absolute configuration',
    label: 'Single diastereomer of unknown absolute configuration',
  },
  { value: 'meso', label: 'meso' },
  { value: 'E', label: 'E' },
  { value: 'Z', label: 'Z' },
  { value: 'mixture E/Z', label: 'mixture E/Z' },
  { value: 'Cis', label: 'Cis' },
  { value: 'Trans', label: 'Trans' },
  { value: 'Endo', label: 'Endo' },
  { value: 'Exo', label: 'Exo' },
  {
    value: 'Mixture of diastereoisomers',
    label: 'Mixture of diastereoisomers',
  },
  { value: 'Atropoisomer A', label: 'Atropoisomer A' },
  { value: 'Atropoisomer B', label: 'Atropoisomer B' },
  {
    value: 'Single enantiomer of unknown absolute configuration first eluting',
    label: 'Single enantiomer of unknown absolute configuration first eluting',
  },
  {
    value: 'Single enantiomer of unknown absolute configuration second eluting',
    label: 'Single enantiomer of unknown absolute configuration second eluting',
  },
  { value: 'Mixture of enantiomers A', label: 'Mixture of enantiomers A' },
  { value: 'Atropoisomer B', label: 'Atropoisomer B' },
  {
    value: 'Mixture of diastereoisomers A',
    label: 'Mixture of diastereoisomers A',
  },
  {
    value: 'Mixture of diastereoisomers B',
    label: 'Mixture of diastereoisomers B',
  },
]

export const batchFields = [
  {
    name: 'Synthesis Quantity (mg)',
    value: 'synthesis_quantity',
    type: 'number',
    req: true,
  },
  {
    name: 'Compound State',
    value: 'state',
    type: 'select',
    req: true,
    options: ['solid', 'gum', 'lyophylate'],
  },
  { name: 'Formula', value: 'formula', type: 'text', req: true },
  {
    name: 'Formula Weight',
    value: 'formula_weight',
    type: 'number',
    req: true,
  },
  {
    name: '1H NMR',
    value: 'nmr',
    type: 'select',
    req: true,
    options: purity,
  },
  { name: 'Barcode 1', value: 'barcode_1', type: 'text', req: true },
  { name: 'Barcode 2', value: 'barcode_2', type: 'text', req: false },
  { name: 'Barcode 3', value: 'barcode_3', type: 'text', req: false },
  { name: 'CRO Barcode 1', value: 'cro_barcode_1', type: 'text', req: false },
  { name: 'CRO Barcode 2', value: 'cro_barcode_2', type: 'text', req: false },
  { name: 'CRO Barcode 3', value: 'cro_barcode_3', type: 'text', req: false },
  {
    name: 'Salt',
    value: 'salt',
    type: 'select',
    req: true,
    options: compoundSalt,
  },
  {
    name: 'Compound Color',
    value: 'color',
    type: 'select',
    req: true,
    options: compoundColor,
  },
  {
    name: 'HPLC Purity',
    value: 'hplc',
    type: 'select',
    req: true,
    options: purity,
  },
  {
    name: 'Chirality',
    value: 'chirality',
    type: 're-select',
    req: true,
    options: chirality,
  },
  {
    name: 'Lab Notebook Reference',
    value: 'eln_reference',
    type: 'text',
    req: true,
  },
  { name: 'Vial Amount 1 (mg)', value: 'amount_1', type: 'text', req: true },
  { name: 'Vial Amount 2 (mg)', value: 'amount_2', type: 'text', req: false },
  { name: 'Vial Amount 3 (mg)', value: 'amount_3', type: 'text', req: false },
  {
    name: 'CRO Vial Amount 1 (mg)',
    value: 'cro_amount_1',
    type: 'text',
    req: false,
  },
  {
    name: 'CRO Vial Amount 2 (mg)',
    value: 'cro_amount_2',
    type: 'text',
    req: false,
  },
  {
    name: 'CRO Vial Amount 3 (mg)',
    value: 'cro_amount_3',
    type: 'text',
    req: false,
  },
  {
    name: 'Shipping Number',
    value: 'shipping_number',
    type: 'text',
    req: false,
  },

  { name: 'Vendor', value: 'vendor', type: 'text', req: false },
  { name: 'Vendor ID', value: 'vendor_id', type: 'text', req: false },
]

export const batchDisplayFields = [
  { name: 'CDD Batch ID', value: 'molecule_batch_id' },
  { name: 'Batch #', value: 'batch' },
  { name: 'Synthesis Quantity', value: 'synthesis_quantity' },
  { name: 'Sate', value: 'state' },
  { name: 'Color', value: 'color' },
  { name: 'FW', value: 'formula_weight' },
  { name: 'ELN', value: 'eln' },
  { name: 'HPLC %', value: 'hplc' },
  { name: 'NMR %', value: 'nmr' },
  { name: 'Shipping', value: 'shipping_number' },
  { name: 'Salt', value: 'salt' },
  { name: 'Chirality', value: 'chirality' },
  { name: 'Lab Notebook Reference', value: 'eln_reference' },
  { name: 'Vendor', value: 'vendor' },
  { name: 'Vendor ID', value: 'vendor_id' },
  { name: 'Vial Codes', value: 'vial_code' },
  { name: 'Vial Amounts', value: 'vial_amount' },
  { name: 'CRO Vial Codes', value: 'cro_vial_code' },
  { name: 'CRO Vial Amounts', value: 'cro_vial_amount' },
  { name: 'Registered', value: 'registered' },
  { name: 'Notes', value: 'notes' },
  // { name: 'Date Registered', value: 'date_registered' },
]

export const makeFields = [
  { name: 'Virtual ID', value: '_id' },
  { name: 'CDD Name', value: 'cdd_name' },
  // { name: 'Alias', value: 'alias' },
  { name: 'Project', value: 'project_code' },
  { name: 'Creator', value: 'creator' },
  { name: 'Method', value: 'method' },
  { name: 'Parent', value: 'parent' },
  // { name: 'Smarts Diff', value: 'smarts_diff' },
  { name: 'CDD ID', value: 'cdd_id' },
  { name: 'Stage', value: 'stage' },
  // { name: 'Notes', value: 'notes' },
  // { name: 'Date Created', value: 'date_created' },
  { name: 'MW', value: 'mw' },
  { name: 'Aromatic Rings', value: 'a_rings' },
  { name: 'Rotatable Bonds', value: 'r_bonds' },
  { name: 'H-Bond Acceptors', value: 'hba' },
  { name: 'H-Bond Donors', value: 'hbd' },
  { name: 'Heavy Atoms', value: 'hac' },
  { name: 'LogP', value: 'logp' },
  { name: 'Molar Refractivity', value: 'mr' },
  { name: 'QED', value: 'qed' },
  { name: 'TPSA', value: 'tpsa' },
]

export const general = [
  { name: 'Parent', value: 'parent', type: 'text' },
  { name: 'Parent Smarts Diff', value: 'smarts_diff', type: 'text' },
  { name: 'Notes', value: 'notes', type: 'textarea' },
]

export const ai = [
  { name: 'BAGEL™ Meta', value: 'bagel_meta', type: 'text' },
  { name: 'SUEDE™ Meta', value: 'suede_meta', type: 'text' },
  { name: 'BAGEL™ SAR Analysis', value: 'bagel_sar', type: 'text' },
  { name: 'SUEDE™ SAR Analysis', value: 'suede_sar', type: 'text' },
  { name: 'CANDID™ SAR Analysis', value: 'candid_sar', type: 'text' },
  { name: 'cLogD Insight', value: 'candid_logd_ic', type: 'text' },
  { name: 'cLogS Insight', value: 'candid_logs_ic', type: 'text' },
  { name: 'cPKA Insight', value: 'candid_pka_ic', type: 'text' },
  { name: 'cCaco Insight', value: 'candid_caco_ic', type: 'text' },
  { name: 'cMPO Insight', value: 'candid_mpo_ic', type: 'text' },
  { name: 'cBBBP Insight', value: 'candid_bbbp_ic', type: 'text' },
  { name: 'cHLM Insight', value: 'candid_hlm_ic', type: 'text' },
  { name: 'BAGEL™ Insight', value: 'bagel_ic', type: 'text' },
  { name: 'SUEDE™ Insight', value: 'suede_ic', type: 'text' },
  { name: 'CANDID™ Insight', value: 'candid_ic', type: 'text' },
]

export const compchem = [
  { name: 'Docking Outcome', value: 'docking_outcome', type: 'text' },
  { name: 'MDS', value: 'mds', type: 'select', options: ['FALSE', 'TRUE'] },
  { name: 'Residue Interaction', value: 'residue_interaction', type: 'text' },
  { name: 'MDS Analysis', value: 'mds_analysis', type: 'text' },
  { name: 'CompChem SAR Analysis', value: 'comp_chem_sar', type: 'text' },
  { name: 'CompChem Insight', value: 'comp_chemist_ic', type: 'text' },
  { name: 'CompChemist', value: 'comp_chemist', type: 'text' },
]

export const medchem = [
  {
    name: 'MedChem SAR Hypothesis',
    value: 'med_chemist_hypothesis',
    type: 'text',
  },
  { name: 'MedChem Analysis', value: 'med_chemist_analysis', type: 'text' },
  { name: 'MedChem Insight', value: 'med_chemist_ic', type: 'text' },
  { name: 'Med Chemist', value: 'med_chemist', type: 'text' },
]
