import { Link } from 'react-router-dom'

export default function Header() {
  return (
    <header className="body-font bg-gray-200">
      <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
        <Link to="/">
          <img
            className="max-w-sm"
            src={`${process.env.PUBLIC_URL}/images/logo.png`}
            alt="1910 Logo"
          />
        </Link>
        <nav className="md:ml-auto flex flex-wrap items-center text-base justify-center"></nav>
      </div>
    </header>
  )
}
