import { createSlice } from '@reduxjs/toolkit'

import { api } from 'api'
const initialState = {
  user: {},
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload
      localStorage.setItem('proton-user', JSON.stringify(action.payload))
    },
    logout: (state) => {
      state.user = {}
      localStorage.removeItem('proton-user')
    },
    setStateUser: (state) => {
      // change to fetch user from database and load state
      const localUser = JSON.parse(localStorage.getItem('proton-user'))
      state.user = localUser
    },
  },
})

export const { setUser, logout, setStateUser } = userSlice.actions
export default userSlice.reducer

export const selectUserGroup = (state) => state.user.user.group
