import { Link } from 'react-router-dom'

export default function MoleculeCard({ molecule }) {
  return (
    <div className="flex-auto mx-4 my-4 max-w-sm rounded-lg overflow-hidden shadow-lg bg-gray-50">
      <Link to={`/molecules/${molecule._id.toString()}`} target="_blank">
        <div className="font-bold text-center text-red-700 text-xl my-2">
          1910V{molecule._id}
        </div>

        <div className="max-w-sm rounded overflow-hidden shadow-lg">
          <img
            className="w-full object-cover"
            src={`${
              process.env.REACT_APP_API_URL
            }/image?smi=${encodeURIComponent(molecule.smiles)}`}
            loading="lazy"
            alt=""
          />
        </div>

        {molecule.shared_with && (
          <div className="rounded-full bg-blue-200 text-xs w-32 px-2 mt-2">
            <p className="font-normal">
              Shared &#xbb; <span>{molecule.shared_with}</span>
            </p>
          </div>
        )}

        <p className="flex justify-end text-sm font-extralight uppercase mr-2">
          {molecule.stage}
        </p>
      </Link>
    </div>
  )
}
