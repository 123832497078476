import UserDropDown from 'components/admin/UserDropDown'

export default function UsersTable({ users }) {
  return (
    <table className="w-full text-sm text-left text-gray-500">
      <thead className="text-xs text-gray-700 uppercase bg-gray-50">
        <tr>
          {Object.keys(users[0]).map((key) => (
            <th key={key} scope="col" className="px-6 py-3">
              {key}
            </th>
          ))}
          <th className="py-3 px-6">Action</th>
        </tr>
      </thead>
      <tbody>
        {users.map((user) => (
          <tr key={user.email} className="bg-white border-b">
            <td className="px-6 py-4">{user.first_name}</td>
            <td className="px-6 py-4">{user.last_name}</td>
            <td className="px-6 py-4">{user.email}</td>
            <td className="px-6 py-4">{user.group_email}</td>
            <td className="px-6 py-4">{user.role}</td>
            <td className="px-6 py-4">{user.group}</td>
            <UserDropDown user={user} />
          </tr>
        ))}
      </tbody>
    </table>
  )
}
