import { configureStore } from '@reduxjs/toolkit'

import userReducer from 'stores/userSlice'
import moleculesReducer from 'stores/moleculesSlice'

export const store = configureStore({
  reducer: {
    user: userReducer,
    molecules: moleculesReducer,
  },
})
