import React from 'react'

export default function AuditTable({ audit_logs }) {
  return (
    <div className="text-xs text-gray-500">
      <h2 className="font-medium mb-1">Audit Log</h2>
      {audit_logs.slice(-4).map((log) => (
        <div key={`${log.user}-${log.date}`}>
          <span className="font-medium mr-2">
            {new Date(log.date).toLocaleDateString()}:
          </span>
          <span className="italic mr-2">{log.user}</span>
          <span className="italic">
            {JSON.stringify(log.description, null, 2)}
          </span>
        </div>
      ))}
    </div>
  )
}
